import { memoizedIntlDateTimeFormat } from './intl';

// Convert date to yyyy-mm-idd
// Source: https://stackoverflow.com/a/29774197/1546808
export function dateToYYYYMMDD(date: Date) {
  const offset = date.getTimezoneOffset();
  date = new Date(date.getTime() - offset * 60 * 1000);
  return date.toISOString().split('T')[0];
}

export function formatDateTime(isoDateString: string): string {
  // Use a memoized Intl.DateTimeFormat to format the date and time
  const formattedDate = memoizedIntlDateTimeFormat('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  }).format(new Date(isoDateString));

  const formattedTime = memoizedIntlDateTimeFormat('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  }).format(new Date(isoDateString));

  return `${formattedDate}, ${formattedTime}`;
}

export const oneWeekMs = 1000 * 60 * 60 * 24 * 7;
export const oneDayMs = 1000 * 60 * 60 * 24;
export const oneHourMs = 1000 * 60 * 60;
export const tenMinutesMs = 1000 * 60 * 10;
export const fiveMinutesMs = 1000 * 60 * 5;
export const twoMinutesMs = 1000 * 60 * 2;

// Get current date in {yyyy}-{mm}-{dd} format in the user's current timezone
export function getCurrentDateForInput() {
  const date = new Date();
  const pad = function (num: number) {
    return (num < 10 ? '0' : '') + num;
  };
  // Adapted from: https://stackoverflow.com/a/17415677/1546808
  return date.getFullYear() + '-' + pad(date.getMonth() + 1) + '-' + pad(date.getDate());
}
