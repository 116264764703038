import memoizeFormatConstructor from 'intl-format-cache';

// SSR workaround for default export not being used during SSR for some reason...
const memoizeFormatConstructorWorkaround = (
  'default' in memoizeFormatConstructor
    ? memoizeFormatConstructor.default
    : memoizeFormatConstructor
) as typeof memoizeFormatConstructor;

// Intl.DateTimeFormat() memoized for performance
export const memoizedIntlDateTimeFormat = memoizeFormatConstructorWorkaround(Intl.DateTimeFormat);
